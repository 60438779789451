import { bindable, containerless } from 'aurelia-framework';
import { SicAlertOccurrence } from '../../../../../services/cyber-api';
import UAParser from 'ua-parser-js';

@containerless()
export class ThreatDetectedSoftware {
    @bindable() private userAgents: UAParser.IResult[];
    @bindable() private occurrences: SicAlertOccurrence[];
    private software: string;
    private viruses: string;

    private occurrencesChanged(): void {
        this.setSoftware();
        this.setViruses();
    }

    private setSoftware(): void {
        const software = this.occurrences
            .map((x) => x.network?.application)
            .filter((x) => x);
        const softwareString = Array.from(new Set(software)).join(', ');
        this.software = softwareString || 'N/A';
    }

    private setViruses(): void {
        const viruses = this.occurrences
            .map((x) => x.virus?.virus)
            .filter((x) => x);
        const virusString = Array.from(new Set(viruses)).join(', ');
        this.viruses = virusString || 'N/A';
    }
}
