import { bindable, computedFrom, containerless } from 'aurelia-framework';
import { SicAlert } from 'services/cyber-api';
import { ISicTraffic } from 'models/sic-traffic';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import { SicUtilities } from 'utilities/sic-utilities';
import { IThreatXChangeDetails } from 'utilities/x-change-utilities';
import showdown from 'showdown';

@containerless()
export class ThreatDetails {
    @bindable() private threat: SicAlert;
    @bindable() private sicTraffic: ISicTraffic;
    // xchange details is used for local ip instead of from sic traffic see #74218 https://dev.azure.com/marlink/Marlink%20Digitalization/_workitems/edit/74218
    @bindable() private xchangeDetails: IThreatXChangeDetails;
    @bindable() private channel: "Universal Threat Management" | "Endpoint Detection and Response" | "Network Threat";

    private copy(value: string): void {
        Utilities.copyToClipboard(value);

        Toast.info('Copied to clipboard');
    }

    @computedFrom('threat.rule')
    private get pattern(): string {
        return SicUtilities.extractPattern(this.threat.rule.pattern);
    }

    /**
     * Computed property to generate the description as html, based on markdown input.
     */
    @computedFrom('threat.details')
    private get htmlDescription(): string {
        return new showdown.Converter({
            // Enable hyperlinks without specific Markdown syntax
            simplifiedAutoLink: true,

            // Open *all* links in new tab. if there are any customizations required, be prepared for a world of pain:
            // https://github.com/showdownjs/showdown/issues/337
            openLinksInNewWindow: true
        })
            .makeHtml(this.threat.details);
    }
}
